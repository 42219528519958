<template>
  <f7-page class="register-page">
    <meta name="robots" content="noindex" />

    <section class="register-container">
      <div class="container">
        <img src="@/assets/images/svg/success.svg" :alt="$t.getTranslation('LBL_CONGRATULATIONS')" loading="lazy" />

        <h1>{{ $t.getTranslation("LBL_CONGRATULATIONS") }}</h1>

        <p>{{ $t.getTranslation("LBL_CONGRATULATIONS_FORGOT_PASSWORD_SUB") }}</p>

        <f7-button fill large raised @click="onLogin"> {{ $t.getTranslation("LBL_SIGN_IN") }} </f7-button>
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "ForgotPasswordSuccessPage",
  components: {},
  props: { f7router: Object },
  setup(props) {

    const store = useStore();
    
    const onLogin = () => {
      store.dispatch("forgotpassword/resetData");
      props.f7router.navigate("/login/");
    };

    return { onLogin };
  },
});
</script>
<style scoped>
.register-container img {
  margin-top: 80px;
}
</style>
